import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.titleWithCompanyAndCustomerName)+" ")])])],1),(_vm.canEdit)?_c(VForm,{ref:"PreNegotiationSignatureForm"},[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"filled":"","dense":"","label":"Nome","placeholder":"Digite o nome do assinante","rules":[_vm.inputRules.required()]},model:{value:(_vm.signatureForm.name),callback:function ($$v) {_vm.$set(_vm.signatureForm, "name", $$v)},expression:"signatureForm.name"}})],1),_c(VCol,[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"filled":"","dense":"","label":"CPF","placeholder":"Digite o CPF do assinante","rules":[_vm.inputRules.required()]},model:{value:(_vm.signatureForm.document),callback:function ($$v) {_vm.$set(_vm.signatureForm, "document", $$v)},expression:"signatureForm.document"}})],1),_c(VCol,[_c(VTextField,{attrs:{"filled":"","dense":"","label":"E-Mail","placeholder":"Digite o e-mail do assinante","rules":[_vm.inputRules.required(), _vm.inputRules.isEmail()]},model:{value:(_vm.signatureForm.mail),callback:function ($$v) {_vm.$set(_vm.signatureForm, "mail", $$v)},expression:"signatureForm.mail"}})],1),_c(VCol,[_c(VSelect,{attrs:{"items":_vm.subscriberTypeItems,"filled":"","dense":"","label":"Tipo","placeholder":"Selecione o tipo","rules":[_vm.inputRules.required()]},model:{value:(_vm.signatureForm.type),callback:function ($$v) {_vm.$set(_vm.signatureForm, "type", $$v)},expression:"signatureForm.type"}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"color":"primary","height":"52px"},on:{"click":_vm.handleAddButtonClick}},[_vm._v(" ADICIONAR ")])],1)],1)],1):_vm._e(),_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"headers":_vm.availableHeaders,"items":_vm.signatureGroup.signatures,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.documentFormattation.formatToCpf(value))+" ")]}},{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c(VChip,{attrs:{"color":_vm.getSubscriberTypeColor(value)}},[_vm._v(" "+_vm._s(_vm.getSubscriberTypeText(value))+" ")])]}},(_vm.canEdit)?{key:"item.action",fn:function(ref){
var index = ref.index;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","button-icon":"mdi-close","tooltip-text":"Desconsiderar Parcela"},on:{"click":function($event){return _vm.handleExcludeSignature(index)}}})]}}:null],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }